import { useMemo } from 'react';
import * as ReactQuery from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { RocketError, RocketErrorKey } from './ErrorHandler';

const whitelistedMutations = [/^sites\/users\/login/, /^login/, /^sites\/users\/.+\/password/];

export default function QueryClientProvider({ children }: { readonly children: React.ReactNode }) {
  /**
   * Handles 401 errors from backend by having the user log in again
   * @param error
   */
  const getQueryClient = useMemo(() => {
    const handleResponseError = async function (error: unknown, type: 'mutation' | 'query') {
      if (error instanceof AxiosError) {
        const url = error.config?.url || '';
        const is401Error = error.response?.status == 401;
        const matchesWhitelistedMutation = whitelistedMutations.some(regExp => regExp.test(url));

        if (url && is401Error && !matchesWhitelistedMutation) {
          throw new RocketError(RocketErrorKey.AuthenticationError);
        }

        if (type === 'query') {
          throw new RocketError(RocketErrorKey.DataFetchingError);
        }
      }
    };

    return new ReactQuery.QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
        mutations: {
          onError: error => {
            handleResponseError(error, 'mutation');
          },
        },
      },
      queryCache: new ReactQuery.QueryCache({
        onError: error => {
          handleResponseError(error, 'query');
        },
      }),
    });
  }, []);

  return (
    <ReactQuery.QueryClientProvider client={getQueryClient}>
      {children}
    </ReactQuery.QueryClientProvider>
  );
}
