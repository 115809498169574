import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosService } from 'api/axiosService';
import { components } from 'openapi-types';

export type Profile = NonNullable<components['schemas']['GetAccountMeResponse']['result']>;
export type Task = NonNullable<Profile['tasks']>[number];

const TASK_POLLING_INTERVAL = 1000;

export type Metric = keyof NonNullable<
  NonNullable<components['schemas']['GetAccountMeResponse']['result']>['usage']
>;

const queryFnUseProfile = async () => {
  const response =
    await AxiosService.get<components['schemas']['GetAccountMeResponse']>(`account/me`);
  const usage = response.data.result?.usage;
  const limits = response.data.result?.limits;

  if (usage && limits) {
    usage.disk = Math.ceil(Number(usage.disk));
    usage.bandwidth = Math.ceil(Number(usage.bandwidth));
    limits.disk = Math.ceil(Number(limits.disk) * 1024);
    limits.bandwidth = Math.ceil(Number(limits.bandwidth) * 1024);
  }
  return response;
};

export const useProfile = (enabled = true) => {
  return useQuery({
    queryKey: ['profile'],
    queryFn: queryFnUseProfile,
    enabled,
    gcTime: 10 * 1000,
    staleTime: 10 * 1000,
  });
};

export const useProfileSuspense = () => {
  return useSuspenseQuery({
    queryKey: ['profile'],
    queryFn: () => queryFnUseProfile(),
    gcTime: 10 * 1000,
    staleTime: 10 * 1000,
  });
};

export const useProfileTaskPolling = (enabled: boolean) => {
  return useQuery({
    queryKey: ['profile'],
    queryFn: queryFnUseProfile,
    refetchInterval: TASK_POLLING_INTERVAL,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: 'always',
    enabled,
  });
};
