import { Box, Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { linkHelper } from 'linkHelper';
import { PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { delay } from 'utils/delay';
import { isInIframe } from 'utils/iframe';

export enum RocketErrorKey {
  DataFetchingError = 'data_fetching_error',
  AuthenticationError = 'authentication_error',
}

export class RocketError extends Error {
  constructor(message: RocketErrorKey) {
    super(message);
  }
}

const ErrorPage = () => {
  const handleReload = window.location.reload;
  const { t } = useTranslation();

  return (
    <Box sx={{ height: '100vh', width: '100vw', display: 'grid', placeItems: 'center' }}>
      <Stack gap={6}>
        <Typography variant="h1"> {t('something_went_wrong')}</Typography>
        <Button variant="contained" color="primary" onClick={handleReload}>
          {t('reload')}
        </Button>
      </Stack>
    </Box>
  );
};

export const ErrorHandler = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const handleUnhandledRejection = async (event: PromiseRejectionEvent) => {
      const defaultMessage = undefined;
      const serverMessage = event.reason?.response?.data?.messages?.[0];
      const errorKey = event.reason instanceof RocketError ? event.reason.message : undefined;

      if (errorKey === RocketErrorKey.DataFetchingError) {
        enqueueSnackbar(t('could_not_load_data'), {
          key: errorKey,
          preventDuplicate: true,
          variant: 'error',
        });
      } else if (errorKey === RocketErrorKey.AuthenticationError) {
        enqueueSnackbar(t('authenticationError'), {
          key: errorKey,
          preventDuplicate: true,
          variant: 'error',
          persist: true,
        });

        if (!isInIframe()) {
          await delay(4000);
          window.location.href = linkHelper.logout.getLink();
        }
      } else {
        enqueueSnackbar(serverMessage || defaultMessage, { variant: 'error' });
      }
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  return <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>;
};
